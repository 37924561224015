<template>
  <div class="auth-wrapper auth-v2">
    <ParticleAnimation />

    <div class="auth-left" ref="authParentBox">
      <div class="auth-box" ref="authChildBox">
        <div class="d-flex justify-content-between">
          <b-link class="">
            <img
              src="@/assets/images/svg/logo.svg"
              style="width: 270px; padding-top: 8px"
            />
          </b-link>
          <div v-if="image">
            <img :src="image.url" style="width: 200px; padding-top: 8px" />
          </div>
        </div>
        <img src="@/assets/images/svg/teethlogin.png" class="teeth" alt="" />
        <img src="@/assets/images/svg/vector.png" class="vectors" alt="" />
        <p>
          {{ $t("Efficiency & Quality") }} -<br />{{
            $t("let AI revolutionize your work")
          }}!
        </p>
      </div>
    </div>
    <div class="auth-right register-page">
      <div class="language-dropdown">
        <ul>
          <Language />
        </ul>
      </div>
      <div class="auth-detail">
        <h3>{{ $t("Referral") }} : {{ companyName }}</h3>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <div class="row">
              <div class="col-md-6 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  rules="required"
                >
                  <TextInput
                    v-model="form.companyName"
                    type="text"
                    :label="$t('Company Name')"
                    :tooltipMessage="$t('Company Name')"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="Address Line 1"
                  rules="required"
                >
                  <TextInput
                    v-model="form.addressLine1"
                    type="text"
                    :label="$t('Address Line 1')"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <TextInput
                  v-model="form.addressLine2"
                  type="text"
                  :label="$t('Address Line 2')"
                />
              </div>
              <div class="col-md-6 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <TextInput
                    v-model="form.city"
                    type="text"
                    :label="$t('City')"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="ZIP"
                  rules="required"
                >
                  <TextInput
                    v-model="form.zipCode"
                    type="text"
                    :label="$t('ZIP')"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <TextInput
                    v-model="form.country"
                    type="text"
                    :label="$t('Country')"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <TextInput
                  v-model="form.vatId"
                  type="text"
                  :label="$t('VAT ID')"
                />
              </div>
              <div class="col-md-6 mb-1">
                <TextInput
                  v-model="form.phone"
                  type="text"
                  :label="$t('Phone')"
                />
              </div>
              <!-- <div class="col-md-6 mb-2"></div> -->
              <div class="col-md-6 mb-1">
                <!-- email -->
                <b-form-group label-for="login-email">
                  <label class="input-label form-label"
                    ><span style="color: red">*</span>&nbsp;{{ $t("Email") }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <input
                      id="email"
                      v-model="form.email"
                      name="title"
                      class="form-control"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6 mb-1">
                <!-- forgot password -->
                <b-form-group>
                  <label for="login-password"
                    ><span style="color: red">*</span>&nbsp;
                    {{ $t("Password") }}
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <label>Captcha:</label>
            <div class="captcha-wrapper">
              <img :src="captchaSrc" alt="captcha" />
              <feather-icon
                @click="fetchCaptcha"
                class="cursor-pointer"
                icon="RefreshCwIcon"
                size="16"
              />
            </div>
            <div class="col-md-4 mb-1">
              <!-- email -->
              <b-form-group label-for="login-captcha">
                <input
                  id="captcha"
                  v-model="captchaInput"
                  name="captcha"
                  class="form-control"
                  type="text"
                />
              </b-form-group>
            </div>

            <div class="d-flex align-items-center justify-content-center">
              <!-- submit buttons -->
              <b-button @click="createCustomer" variant="primary" block>
                {{ $t("Register") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
    <b-modal
      id="edit-tag-modal"
      v-model="showRegisterModal"
      title="Registration Successful"
      centered
      size="md"
      hide-footer
      @hidden="refreshPage"
    >
      <p>
        {{ $t("Going live of DentalTwin is on the 1st of April.") }}
      </p>
      <p>
        {{
          $t(
            "You will receive an e-mail in the next few days to confirm all your information."
          )
        }}
      </p>
      <div class="d-flex align-items-center justify-content-end mt-2">
        <b-button @click="refreshPage()" variant="primary">{{
          $t("Ok")
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { detectBot } from "../services/botd.service";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import ParticleAnimation from "@/components/ParticleAnimation.vue";
import Language from "@/components/Language.vue";
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import NotificationService from "../services/notification.service";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ParticleAnimation,
    Language,
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    MultiSelectInput,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipCode: "",
        country: "",
        vatId: "",
        phone: "",
        status: "active",
      },
      showRegisterModal: false,
      // portalBaseUrl: process.env.VUE_APP_PORTAL_BASEURL || "",
      captchaInput: "",
      image: {},
      captchaSrc: "",
      captchaKey: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login/login.png"),
      companyName: "",
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  async created() {
    this.fetchCaptcha();
    this.$store.commit("showLoader", true);
    await this.$store
      .dispatch("customers/showReferralCompany", this.$route.query.companyId)
      .then((res) => {
        let data = res?.data?.modelData;
        this.companyName = data.companyName;
        this.image = data.image ?? {};
      });
  },
  mounted() {
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    async fetchCaptcha() {
      await this.$store.dispatch("customers/fetchCaptcha").then((res) => {
        this.captchaSrc = res?.data?.key?.img;
        this.captchaKey = res?.data?.key?.key;
      });
    },
    setWidth() {
      const parentWidth = this.$refs.authParentBox.offsetWidth;
      const parentheight = this.$refs.authParentBox.offsetHeight;
      this.$refs.authChildBox.style.width = `${parentWidth}px`;
      this.$refs.authChildBox.style.height = `${parentheight}px`;
    },
    async createCustomer() {
      const botDetection = await detectBot();

      if (botDetection.bot) {
        NotificationService.showError(
          $t("Something went wrong. Please contact support")
        );
        return;
      }
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.$store.commit("showLoader", true);
          await this.$store
            .dispatch("customers/registerCompany", {
              ...this.form,
              referralId: this.$route.query.companyId,
              captcha: this.captchaInput,
              key: this.captchaKey,
            })
            .then(async (res) => {
              this.$store.commit("showLoader", false);
              this.showRegisterModal = true;
              // window.location.href = this.portalBaseUrl + "/home";
            })
            .finally(() => {
              this.captchaInput = "";
              this.fetchCaptcha();
              this.$store.commit("showLoader", false);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.captcha-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
